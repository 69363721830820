import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import logo from '../assets/images/asgaard.svg'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import Scroll from '../components/Scroll'

import devvjob from '../assets/images/devvjob.png'

import wellburrito from '../assets/images/wellburrito.webp'
import breathe from '../assets/images/breathe.webp'
import voice from '../assets/images/voice-icon.svg'

import v1 from '../assets/images/voice.webp'
import v3 from '../assets/images/voice3.webp'
import v4 from '../assets/images/voice4.webp'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Asgaard Software | Bespoke software development in Birmingham, UK" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>About Asgaard</h2>
                </header>
                <p className=''> Asgaard Software is a software development provider
                based in Birmingham, UK.
                  </p>
                <p>
                  We develop bespoke, custom software, catered for your needs. We specialise in web and mobile apps,
                  with many projects comprising a front-end web or mobile app backed by a web based management system.
                </p>
                <p className=''> Have a project to
                discuss? <Scroll type="id" element="inquiry-form-wrapper">
                    <a href="#">Get in touch</a>
                  </Scroll>, and we'll get back to you.
                  </p>

              </div>
              <span className="image">
                <img src={logo} alt="" />
              </span>
            </div>
          </section>

          <section id="our-work" className="main special">
            <header className="major">
              <h2>Our Work</h2>
            </header>
            <p>Here are a few examples of the kinds of things we can do.</p>
            <ul className="features">
              <li className='full-width devvjob'>
                <h3>Devvjob</h3>

                <div className="image">
                  <img src={devvjob} alt="" />
                </div>
                <h4>
                  Devvjob is a recruitment platform and job vacancy search engine.
                </h4>
                <p>
                  Devvjob uses <strong>natural language processing</strong> (NLP)
                  and <strong>artificial intelligence</strong> (AI) to analyse
                  and understand job listings, thereby allowing stronger
                  automatic matching of vacancies to candidates.
                </p>
                <p>Devvjob comprises three parts:
                  <ol>
                    <li>An <strong>offline crawler</strong>, where the NLP and AI happens,</li>
                    <li>an <strong>online database</strong> and <strong>web API</strong>, providing real time search results, and</li>
                    <li>a fast and responsive <strong>single-page-application</strong> front end. </li>
                  </ol>
                </p>
                <ul className="actions">
                  <li>
                    <Link to="/p/devvjob" className="button">
                      Read more about Devvjob
                    </Link>
                  </li>
                </ul>
              </li>


              <li className="recorder full-width ">
                <h3> <img src={voice} />  Asgaard Easy Voice</h3>
                <p>
                Asgaard Easy Voice is a sound recording app, with automatic sync of recorded files to Google Drive.
                </p>
                <div class='images app-promo'>

                  <img src={v1} alt="Record your thoughts, ideas and meetings" class='double' />
                  <img src={v3} alt="Enjoy a modern, dark mode user interface" />
                  <img src={v4} alt="Listen to, manage and share your recordings" />
                </div>              

                <ul className="actions">
                  <li>
                    <a href="/p/voicerecorder" className="button">
                      Read more about Asgaard Easy Voice
                  </a>
                  </li>
                </ul>
              </li>

              <li className="breathe">
                <h3>              <div className="image">
                  <img src="//asgaard.co.uk/breathe/images/logo.svg" alt="Breathe" />
                </div></h3>

                <div className="image">
                  <img src={breathe} alt="Breathe" />
                </div>

                <p>
                  Breathe: Calm is a relaxation app, to help you relax through guided breathing exercises
                </p>
                <ul className="actions">
                  <li>
                    <a href="/breathe" className="button">
                      Learn more about Breathe
                    </a>
                  </li>
                </ul>
              </li>
              <li className="wellburrito">
                <h3>Wellburrito</h3>
                <div className="image">
                  <img src={wellburrito} alt="" />
                </div>
                <p>
                  Wellburrito is a wellbeing companion app.
                </p>
                <ul className="actions">
                  <li>
                    <a href="/wellburrito" className="button">
                      Read more about Wellburrito
                  </a>
                  </li>
                </ul>
              </li>

              
            </ul>
          </section>

          {/* <section id="second" className="main special">
            <header className="major">
              <h2>Ipsum consequat</h2>
              <p>
                Donec imperdiet consequat consequat. Suspendisse feugiat congue
                <br />
                posuere. Nulla massa urna, fermentum eget quam aliquet.
              </p>
            </header>
            <ul className="statistics">
              <li className="style1">
                <span className="icon fa-code-fork"></span>
                <strong>5,120</strong> Etiam
              </li>
              <li className="style2">
                <span className="icon fa-folder-open-o"></span>
                <strong>8,192</strong> Magna
              </li>
              <li className="style3">
                <span className="icon fa-signal"></span>
                <strong>2,048</strong> Tempus
              </li>
              <li className="style4">
                <span className="icon fa-laptop"></span>
                <strong>4,096</strong> Aliquam
              </li>
              <li className="style5">
                <span className="icon fa-diamond"></span>
                <strong>1,024</strong> Nullam
              </li>
            </ul>
            <p className="content">
              Nam elementum nisl et mi a commodo porttitor. Morbi sit amet nisl
              eu arcu faucibus hendrerit vel a risus. Nam a orci mi, elementum
              ac arcu sit amet, fermentum pellentesque et purus. Integer maximus
              varius lorem, sed convallis diam accumsan sed. Etiam porttitor
              placerat sapien, sed eleifend a enim pulvinar faucibus semper quis
              ut arcu. Ut non nisl a mollis est efficitur vestibulum. Integer
              eget purus nec nulla mattis et accumsan ut magna libero. Morbi
              auctor iaculis porttitor. Sed ut magna ac risus et hendrerit
              scelerisque. Praesent eleifend lacus in lectus aliquam porta. Cras
              eu ornare dui curabitur lacinia.
            </p>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Congue imperdiet</h2>
              <p>
                Donec imperdiet consequat consequat. Suspendisse feugiat congue
                <br />
                posuere. Nulla massa urna, fermentum eget quam aliquet.
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button special">
                    Get Started
                  </Link>
                </li>
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
          </section> */}

          <section id='inquiry-form-wrapper' className='main special'>
            <header className="major">
              <h2>Inquiries</h2>
            </header>
            <form method="post" action="/" id='contact'>
              <div className="row">
                <div className="name">
                  <input name="name" placeholder="Name" type="text" />
                </div>
                <div className="email">
                  <input name="email" placeholder="Email" type="email" />
                </div>
              </div>
              <div className='row'>
                <div className="message">
                  <textarea name="message" placeholder="Message"></textarea>
                </div>
              </div>
              <div className='row buttons'>
                <ul className="actions">
                  <li><input type="submit" value="Send Message" /></li>
                  <li><input type="reset" value="Clear form" /></li>
                </ul>
                <div className='error'></div>
              </div>
            </form>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
